
* {
  font-family: 'Lato', sans-serif;
}
body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: subpixel-antialiased;
  font-smooth: always;
}
.scPage::-webkit-scrollbar {
  width: 0!important;
}
input, textarea, select, button, Input, Button, Select {
	outline: none!important;
  resize: none!important;
  color: #000!important;
}



.noscbar::-webkit-scrollbar {
  display: none!important;
}
.noscbar {
  scrollbar-width: none!important;
}

.carousel .slide {
	cursor: auto;
}





.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(225,225,225);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #007cff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #007cff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}